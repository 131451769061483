import * as React from "react"
import Layout from "../templates/layout";
import FullApp from "../FullApp";
import Panel from "../components/panel";
import UserPanel from "../components/panel/pages/user-panel";

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Panel {...props}>
        <UserPanel/>
      </Panel>
    </Layout>
  </FullApp>
);