import * as React from "react";
import {Container, Header, Segment, Grid, Form, Checkbox, Button, Popup, Input, Message} from "semantic-ui-react";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import {t} from "../../../i18n";
import {toast} from "react-toastify";
import PasswordStrengthBar from 'react-password-strength-bar';
import Cloud from "../../backgrounds/others/Cloud";

interface StateProps {
  statements : Array<object>,
  responseStatements: any,
  user: any,
  responsePassword: any,
  responseUserName: any,
}

function mapStateToProps(state: any) {
  return {
  responseStatements: state.sendStatements,
  statements: state.getStatements,
  user: state.user,
  responsePassword: state.updatePassword,
  responseUserName: state.updateUserName,
  };
}

interface DispatchProps {
  sendStatements: typeof actions.sendStatements
  getStatements: typeof actions.getStatements,
  getUser: typeof actions.fetchUser,
  updatePassword: typeof actions.updatePassword,
  updateUserName: typeof actions.updateUserName,
}

const mapDispatchToProps = {
  sendStatements: actions.sendStatements,
  getStatements: actions.getStatements,
  getUser: actions.fetchUser,
  updatePassword: actions.updatePassword,
  updateUserName: actions.updateUserName,
}

type Props = StateProps & DispatchProps;

interface State {
  newStatements: Array<string>,
  oldStatements: any,
  disabledButtonStatements: boolean,
  disabledButtonData: boolean,
  disabledButtonPassword: boolean,
  name: string,
  mail: string,
  password: string,
  rePassword: string,
}

class UserPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      newStatements: [],
      oldStatements: [],
      disabledButtonStatements: false,
      disabledButtonData: false,
      disabledButtonPassword: false,
      name: "",
      mail: "",
      password: "",
      rePassword: "",
    }
  }

  setCheckboxesBasedOnUserStatements(){
  let _newStatements = [];
  this.props.statements.data.map(item => {
    if (item.value === 1){
      _newStatements.push(item.slug)
    }
  })
    this.setState({
      newStatements: _newStatements
    })
  }

  componentDidMount(): void {
    this.props.getStatements();
        this.props.getUser();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
   if (prevProps.statements !== this.props.statements){
     this.setState({
       oldStatements: this.props.statements
     })
     this.setCheckboxesBasedOnUserStatements()
   }

    if (prevProps.responseStatements !== this.props.responseStatements){
      toast.info("Twoje dane zostały zaktualizowane", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({
        disabledButtonStatements: false
      })
    }
    if (prevProps.user !== this.props.user){
      this.setState({
        name: this.props.user.data.name,
        mail: this.props.user.data.email,
      })
    }

    if (prevProps.responsePassword !== this.props.responsePassword){
      if (this.props.responsePassword.status === 200){
        toast.info("Twoje hasło zostało zmienione", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.setState({
        disabledButtonPassword: false
      })
    }

    if (prevProps.responseUserName !== this.props.responseUserName){
        if (this.props.responseUserName.status === 200) {
          toast.info("Twoje imię i nazwisko zostało zaktualizowane", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      this.setState({
        disabledButtonData: false
      })
    }
  }

  canIUpdateName(){
    return (this.state.name !== "")
  }

  updateName(){
    if (this.canIUpdateName()){
      this.props.updateUserName({name: this.state.name})
      this.setState({
        disabledButtonData: true
      })
    }
  }

  canIUpdatePassword(){
    return (this.state.password === this.state.rePassword)
  }

  updatePassword(){
    if (this.canIUpdatePassword()){
      this.props.updatePassword({password: this.state.password})
      this.setState({
        disabledButtonPassword: true
      })
    }
  }

  handleStatementChange(e, {name}){
    let list = this.state.newStatements;
    if (list.includes(name)){
      list.splice(list.indexOf(name), 1)
    }else {
      list.push(name)
    }
    this.setState({
      newStatements: list
    })
  }

  handleValueChange(e, {name, value}) {
    this.setState({[name]: value})
  }

  setStatements(e){
  e.preventDefault()
  let id = actions.getUserIdByToken();
  let outputObject = [];
  this.state.oldStatements.data.map(i => {
    outputObject.push({
      slug: i.slug,
      value: (this.state.newStatements.includes(i.slug) ? 1 : 0)
    })
  })
  this.props.sendStatements({
    userId: id,
    statements: outputObject
  });
  this.setState({
    disabledButtonStatements: true,
  })
  }

  render() {
      return (
        <Cloud>
          <Segment basic id="cloud-panel" style={{height: "100vh", display: "flex", alignItems: "center", flexDirection: "column", paddingTop: "150px"}}>
            <Container>
              <div className="register-form-container">
              <Header as='h1' textAlign="center" style={{marginBottom: "10px"}}>{t('Panel użytkownika')}</Header>
                <Segment basic>
                  <Header as='h3' textAlign={"center"}
                          style={{marginBottom: "50px"}}>{t('Aktualizuj dane osobowe')}</Header>
                  <Form onSubmit={() => this.updateName()}>
                      <Form.Field
                          label={"imię i nazwisko"}
                          name={"name"}
                          value={this.state.name}
                          control={Input}
                          placeholder='imię'
                          onChange={this.handleValueChange.bind(this)}
                      />
                    <Form.Field
                        disabled
                        label={"E-mail"}
                        name={"mail"}
                        value={this.state.mail}
                        control={Input}
                        placeholder='E-Mail'
                        onChange={this.handleValueChange.bind(this)}
                    />
                    <Form.Field>
                      <Grid textAlign='center'>
                        <Button disabled={this.state.disabledButtonData || !this.canIUpdateName()} primary className="btn-primary shadow-light btn-send" type='submit'>aktualizuj dane</Button>
                      </Grid>
                    </Form.Field>
                  </Form>
                </Segment>

                <Segment basic>
                  <Header as='h3' textAlign={"center"} style={{marginBottom: "50px"}}>{t('Zmień hasło')}</Header>
                  <Form onSubmit={() => this.updatePassword()}>
                    <Form.Group widths={"equal"}>
                      <Form.Field>
                      <Form.Input
                          required
                          type="password"
                          label={"Nowe hasło"}
                          name={"password"}
                          value={this.state.password}
                          control={Input}
                          placeholder='Nowe hasło'
                          onChange={this.handleValueChange.bind(this)}
                      />
                      <PasswordStrengthBar password={this.state.password}
                                          shortScoreWord={'hasło jest za krótkie'}
                                          minLength="6"
                      />
                      </Form.Field>
                      <Form.Input
                          required
                          type="password"
                          label={"Powtórz nowe hasło"}
                          name={"rePassword"}
                          value={this.state.rePassword}
                          control={Input}
                          placeholder='Powtórz nowe hasło'
                          onChange={this.handleValueChange.bind(this)}
                      />

                    </Form.Group>
                    {this.state.password !== this.state.rePassword && <Message color={"red"} content="podane hasła różnią się od siebie"/>}
                    <Form.Field>
                      <Grid textAlign='center'>
                        <Button disabled={this.state.disabledButtonPassword || !this.canIUpdatePassword()} primary className="btn-primary shadow-light btn-send" type='submit'>zmień hasło</Button>
                      </Grid>
                    </Form.Field>
                  </Form>
                </Segment>

                <Segment basic>
                <Header as='h3' textAlign={"center"} style={{marginBottom: "50px"}}>{t('Zarządzaj uprawnieniami dotyczącymi twoich danych')}</Header>
                <Form onSubmit={this.setStatements.bind(this)}>
                  <Form.Field required>
                    <Popup content={t("Ta zgoda jest wymagana")} trigger={
                      <Checkbox
                          label={t('pure_data_survey')}
                          name="pure_data_survey"
                          checked={this.state.newStatements.includes("pure_data_survey")}
                          onClick={ this.handleStatementChange.bind(this)}
                      />
                    }/>
                    <Form.Field>
                      {!this.state.newStatements.includes("pure_data_survey")
                      && <Segment basic>
                        <Header as='h5' textAlign={"center"} style={{color: "red"}}>
                          {t('Powyższa zgoda jest wymagana do przeprowadzania jakichkolwiek działań i korzystania z naszych usług')}
                        </Header>
                      </Segment>}
                    </Form.Field>
                  </Form.Field>
                  <Form.Field >
                    <Checkbox
                        label={t('pure_contact')}
                        name="pure_contact"
                        checked={this.state.newStatements.includes("pure_contact")}
                        onClick={ this.handleStatementChange.bind(this)}
                    />
                  </Form.Field>
                  <Form.Field >
                    <Checkbox
                        label={t('pure_marketing')}
                        name="pure_marketing"
                        checked={this.state.newStatements.includes("pure_marketing")}
                        onClick={ this.handleStatementChange.bind(this)}
                    />
                  </Form.Field>
                  <Form.Field >
                    <Checkbox
                        label={t('pure_newsletter')}
                        name="pure_newsletter"
                        checked={this.state.newStatements.includes("pure_newsletter")}
                        onClick={ this.handleStatementChange.bind(this)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Grid textAlign='center'>
                      <Button disabled={this.state.disabledButtonStatements} primary className="btn-primary shadow-light btn-send" type='submit'>aktualizuj zgody</Button>
                    </Grid>
                  </Form.Field>
                </Form>
                </Segment>
              </div>
            </Container>
          </Segment>
        </Cloud>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);